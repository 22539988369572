import Plausible from 'plausible-tracker'

const plausible = Plausible({
  domain: 'yotilastot.fi',
  trackLocalhost: false,
})

function prepareUrl(params: string[]): string {
  // eslint-disable-next-line no-restricted-globals
  const url = new URL(location.href)
  let customUrl = url.protocol + '//' + url.hostname + url.pathname
  if (customUrl.endsWith('/')) customUrl = customUrl.slice(0, customUrl.length - 1)
  for (const p of params) {
    customUrl = customUrl + '/' + p
  }
  return customUrl
}

function trackFakePage(urlSegments: string[]): void {
  plausible.trackPageview({
    url: prepareUrl(urlSegments),
  })
}

function trackEvent(name: string, props: Record<string, string>): void {
  plausible.trackEvent(name, { props })
}

export { trackFakePage, trackEvent }