import React from 'react'
import styled from 'styled-components'
import { Schedule, useSchedule, useTerms } from '../api/api'

function selectCurrentSchedule(allSchedules: Schedule[]) {
  const list = allSchedules
    .filter(s => s.showSince < new Date().getTime())
    .filter(s => s.showUntil > new Date().getTime())
  if (list.length === 0) return undefined
  return list[list.length - 1]
}

export default function ScheduledNotification() {
  const terms = useTerms()
  const schedule = useSchedule()

  if (terms.isLoading || schedule.isLoading) return null
  if (!terms.data || terms.data.length === 0) return null
  if (!schedule.data) return null
  
  const currSchedule = selectCurrentSchedule(schedule.data)
  if (!currSchedule) return null
  
  const lastTerm = terms.data[terms.data.length - 1]
  const isPublished = lastTerm === currSchedule.term

  if (isPublished) {
    return <NotificationGreen>{currSchedule.textAfter}</NotificationGreen>
    
  } else {
    return <NotificationRed>{currSchedule.textBefore}</NotificationRed>
  }
}

const NotificationGreen = styled.p`
  font-weight: 700;
  padding: 1rem 1rem;
  border-left: 0.4rem solid #006600;
  background-color: #CCFFDD;
`

const NotificationRed = styled.p`
  font-weight: 700;
  padding: 1rem 1rem;
  border-left: 0.4rem solid #AA0000;
  background-color: #FFCCCC;
`