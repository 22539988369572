import React from 'react'
import styled from 'styled-components'
import { useData } from '../api/api'
import constants from '../constants'
import { useGlobalState } from '../state/hooks'
import { spacifyNumber, termToObject } from '../utils'
import GradeDistribution from './GradeDistribution'

export type SimpleStatProps = {
  county: number | null,
  school: number | null,
  location: string,
}

function SimpleStats(props: SimpleStatProps) {

  const [{ selectedTerm, selectedBackground }] = useGlobalState()

  const stats = useData(props.county, props.school, selectedTerm, selectedBackground)

  const data = stats.data?.stats ? stats.data.stats : undefined
  const term = termToObject(selectedTerm)

  console.log(props, stats.data, data, stats.data?.stats)

  return (
    <StatsContainer>
      {stats.isLoading && (
        <LoadingText>Ladataan tilastoja...</LoadingText>
      )}

      {data !== undefined && (
        <SingleStatContainer>
          <h4>{props.location}, {term.isAutumn ? 'syksyllä' : 'keväällä'} {term.year} valmistuneet</h4>
          <StatsRow>
            <StatsCol>
              <StatsText>Yhteensä valmistui</StatsText>
              <StatsNumber>{spacifyNumber(data.count.toString())}</StatsNumber>
              <StatsText>ylioppilasta...</StatsText>
            </StatsCol>
            <StatsCol>
              <StatsText>...jotka kirjoittivat</StatsText>
              <StatsNumber>{spacifyNumber(data.total_exams.toString())}</StatsNumber>
              <StatsText>koesuoritusta...</StatsText>
            </StatsCol>
            <StatsCol>
              <StatsText>...eli keskimäärin</StatsText>
              <StatsNumber>{data.average_exams.toFixed(2)}</StatsNumber>
              <StatsText>ainetta henkilöä kohden.</StatsText>
            </StatsCol>
          </StatsRow>
        </SingleStatContainer>
      )}

      {!stats.isLoading && typeof stats.data !== 'undefined' && data === undefined && (
        <LoadingText>Tästä lukiosta ei valitettavasti löytynyt tietoja.</LoadingText>
      )}

      <GradeDistribution county={props.county} school={props.school} location={props.location} />
    </StatsContainer>
  )
}


const StatsContainer = styled.div`
  margin: ${constants.pagePadding} 0;
  overflow: hidden;
`

const LoadingText = styled.div`
  color: black;
  padding: 5rem 1rem;
  ${constants.headingFont};
  font-size: 2rem;
  text-align: center;
`

const SingleStatContainer = styled.div`
  background: black;
  margin: ${constants.pagePadding} 0;
  padding: ${constants.pagePadding};
  color: white;

  h4 {
    font-size: 2rem;
    margin: 0 0 1rem 0;
    text-align: center;
    letter-spacing: 0.05em;
  }

`

const StatsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: black;
  color: white;
`

const StatsCol = styled.div`
  flex: 1;
  text-align: center;
  padding: 1rem;
`

const StatsNumber = styled.div`
  ${constants.headingFont}
  font-size: 3rem;
`

const StatsText = styled.div`
  font-size: 1.6rem;
  margin: 0rem 0;
`

export default SimpleStats