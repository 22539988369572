
const constants = {
  textFont: 'Nunito',
  headingFont: 'font-family: "Abril Fatface", "Times New Roman", serif; font-weight: 400; letter-spacing: 0.05em;',
  pagePadding: '2rem',
  color: {
    red: '#FF2222',
    blue: '#0044DD',
  },
  betterExamCodes: {
    'A': 'ÄI',
    'A5': 'A5',
    'BA': 'RUA',
    'BB': 'RUB',
    'BI': 'BI',
    'CA': 'CA',
    'CB': 'CB',
    'DC': 'DC',
    'EA': 'ENA',
    'EC': 'ENC',
    'ET': 'ET',
    'FA': 'FRA',
    'FC': 'FRC',
    'FF': 'FI',
    'FY': 'FY',
    'GC': 'GC',
    'GE': 'GE',
    'HI': 'HI',
    'I': 'I',
    'IC': 'IC',
    'KE': 'KE',
    'L1': 'L1',
    'L7': 'L7',
    'M': 'MAA',
    'N': 'MAB',
    'O': 'O',
    'O5': 'O5',
    'PA': 'PA',
    'PC': 'PC',
    'PS': 'PS',
    'Q': 'Q',
    'QC': 'QC',
    'SA': 'SA',
    'SC': 'SC',
    'TC': 'TC',
    'TE': 'TE',
    'UE': 'UE',
    'UO': 'UO',
    'VA': 'VA',
    'VC': 'VC',
    'W': 'W',
    'YH': 'YH',
    'Z': 'Z'
  },
  examNames: {
    fi: {
      'YHT': 'Kaikki kokeet yhteensä',
      'A': 'Äidinkieli, suomi',
      'A5': 'Suomi toisena kielenä',
      'BA': 'Ruotsi, pitkä oppimäärä',
      'BB': 'Ruotsi, keskipitkä oppimäärä',
      'BI': 'Biologia',
      'CA': 'Suomi, pitkä oppimäärä',
      'CB': 'Suomi, keskipitkä oppimäärä',
      'DC': 'Pohjoissaame, lyhyt oppimäärä',
      'EA': 'Englanti, pitkä oppimäärä',
      'EC': 'Englanti, lyhyt oppimäärä',
      'ET': 'Elämänkatsomustieto',
      'FA': 'Ranska, pitkä oppimäärä',
      'FC': 'Ranska, lyhyt oppimäärä',
      'FF': 'Filosofia',
      'FY': 'Fysiikka',
      'GC': 'Portugali, lyhyt oppimäärä',
      'GE': 'Maantiede',
      'HI': 'Historia',
      'I': 'Äidinkieli, inarinsaame',
      'IC': 'Inarinsaame, lyhyt oppimäärä',
      'KE': 'Kemia',
      'L1': 'Latina, lyhyt oppimäärä',
      'L7': 'Latina, pidempi oppimäärä',
      'M': 'Matematiikka, pitkä oppimäärä',
      'N': 'Matematiikka, lyhyt oppimäärä',
      'O': 'Äidinkieli, ruotsi',
      'O5': 'Ruotsi toisena kielenä',
      'PA': 'Espanja, pitkä oppimäärä',
      'PC': 'Espanja, lyhyt oppimäärä',
      'PS': 'Psykologia',
      'Q': '- ei käytössä -',
      'QC': 'Koltansaame, lyhyt oppimäärä',
      'SA': 'Saksa, pitkä oppimäärä',
      'SC': 'Saksa, lyhyt oppimäärä',
      'TC': 'Italia, lyhyt oppimäärä',
      'TE': 'Terveystieto',
      'UE': 'Evankelis-luterilainen uskonto',
      'UO': 'Ortodoksi uskonto',
      'VA': 'Venäjä, pitkä oppimäärä',
      'VC': 'Venäjä, lyhyt oppimäärä',
      'W': 'Äidinkieli, koltansaame',
      'YH': 'Yhteiskuntaoppi',
      'Z': 'Äidinkieli, pohjoissaame'
    },
    sv: {
      'A': 'Modersmålet, finska',
      'A5': 'Finska som andraspråk',
      'BA': 'Svenska, lång lärokurs',
      'BB': 'Svenska, medellång lärokurs',
      'BI': 'Biologi',
      'CA': 'Finska, lång lärokurs',
      'CB': 'Finska, medellång lärokurs',
      'DC': 'Nordsamiska, kort lärokurs',
      'EA': 'Engelska, lång lärokurs',
      'EC': 'Engelska, kort lärokurs',
      'ET': 'Livsåskådningskunskap',
      'FA': 'Franska, lång lärokurs',
      'FC': 'Franska, kort lärokurs',
      'FF': 'Filosofi',
      'FY': 'Fysik',
      'GC': 'Portugisiska, kort lärokurs',
      'GE': 'Geografi',
      'HI': 'Historia',
      'I': 'Modersmålet, enaresamiska',
      'IC': 'Enaresamiska, kort lärokurs',
      'KE': 'Kemi',
      'L1': 'Latin, kort lärokurs',
      'L7': 'Latin, längre lärokurs',
      'M': 'Matematik, lång lärokurs',
      'N': 'Matematik, kort lärokurs',
      'O': 'Modersmålet, svenska',
      'O5': 'Svenska som andraspråk',
      'PA': 'Spanska, lång lärokurs',
      'PC': 'Spanska, kort lärokurs',
      'PS': 'Psykologi',
      'Q': '- ej i bruk -',
      'QC': 'Skoltsamiska, kort lärokurs',
      'SA': 'Tyska, lång lärokurs',
      'SC': 'Tyska, kort lärokurs',
      'TC': 'Italienska, kort lärokurs',
      'TE': 'Hälsokunskap',
      'UE': 'Evangelisk-luthersk religion',
      'UO': 'Ortodox religion',
      'VA': 'Ryska, lång lärokurs',
      'VC': 'Ryska, kort lärokurs',
      'W': 'Modersmålet, skoltsamiska',
      'YH': 'Samhällslära',
      'Z': 'Modersmålet, nordsamiska'
    }
  }
}

export default constants