import ky from 'ky'
import { useQuery } from 'react-query'
import { numberGrades } from '../utils'

const API_PATH = 'https://yotilastot.fi/api'
//const API_PATH = 'http://yotilastot.fi.storage.googleapis.com/api'
//const API_PATH = 'http://localhost:3001'

const REACT_QUERY_OPTIONS = {
  staleTime: Infinity,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  retry: false,
}

export type County = {
  id: number,
  name: string,
}

export type School = {
  school_id: number,
  name: string,
  language: string,
  municipality_id: number,
}

export function useCounties(): County[] {
  return [
    { id: 1, name: 'Uusimaa', },
    { id: 2, name: 'Varsinais-Suomi', },
    { id: 4, name: 'Satakunta', },
    { id: 5, name: 'Kanta-Häme', },
    { id: 6, name: 'Pirkanmaa', },
    { id: 7, name: 'Päijät-Häme', },
    { id: 8, name: 'Kymenlaakso', },
    { id: 9, name: 'Etelä-Karjala', },
    { id: 10, name: 'Etelä-Savo', },
    { id: 11, name: 'Pohjois-Savo', },
    { id: 12, name: 'Pohjois-Karjala', },
    { id: 13, name: 'Keski-Suomi', },
    { id: 14, name: 'Etelä-Pohjanmaa', },
    { id: 15, name: 'Pohjanmaa', },
    { id: 16, name: 'Keski-Pohjanmaa', },
    { id: 17, name: 'Pohjois-Pohjanmaa', },
    { id: 18, name: 'Kainuu', },
    { id: 19, name: 'Lappi', },
    { id: 21, name: 'Ahvenanmaa', },
  ]
}

type RawSchedule = {
  term: string,
  showSince: string,
  showUntil: string,
  textBefore: string,
  textAfter: string,
}
export type Schedule = {
  term: string,
  showSince: number,
  showUntil: number,
  textBefore: string,
  textAfter: string,
}
export function useSchedule() {
  return useQuery(['schedule'], fetchSchedule, REACT_QUERY_OPTIONS)
}
async function fetchSchedule(key: string): Promise<Schedule[]> {
  const list: RawSchedule[] = await ky.get(`${API_PATH}/schedule.json`).json()
  return list.map(s => ({
    ...s,
    showSince: new Date(s.showSince).getTime(),
    showUntil: new Date(s.showUntil).getTime(),
  }))
}


export function useTerms() {
  return useQuery(['terms'], fetchTerms, REACT_QUERY_OPTIONS)
}

async function fetchTerms(key: string): Promise<string[]> {
  return await ky.get(`${API_PATH}/terms.json`).json()
}



// Query types

export type Background = 'actual' | 'all'

export type CountryQuery = {
  county: null,
  school: null,
  term: string,
  background: Background,
}

export type CountyQuery = {
  county: number,
  school: null,
  term: string,
  background: Background,
}

export type SchoolQuery = {
  county: number,
  school: number,
  term: string,
  background: Background,
}

type DataQuery = CountryQuery | CountyQuery | SchoolQuery

// Original result types

type OriginalDataResult<TQuery extends DataQuery> = {
  stats: SimpleStat,
  distribution: OriginalGradeDistributionResultv2,
  // Schools list is only available for county data
  schools: TQuery extends CountyQuery
  ? School[]
  : undefined,
  // Grades list is only available for school data
  grades: TQuery extends SchoolQuery
  ? GradesRow[] | null
  : undefined,
}

type OriginalGradeDistributionResultv2 = {
  distribution: OriginalGradeDistributionRow[] | null,
  students: number,
}

type OriginalGradeDistributionRow = {
  exam_code: string,
  result: number,
  count: number,
}

// Output result types

type DataResult<TQuery extends DataQuery> = {
  stats: SimpleStat,
  distribution: GradeDistributionResult,
  // Schools list is only available for county data
  schools: TQuery extends CountyQuery
  ? School[]
  : undefined,
  // Grades list is only available for school data
  grades: TQuery extends SchoolQuery
  ? GradesRow[] | null
  : undefined,
}

export type GradeDistributionResult = {
  distribution: GradeDistributionRow[] | null,
  students: number,
}


export type GradeDistributionRow = {
  exam_code: string,
  result: string,
  count: number,
}

export type GradesRow = {
  average_points: number,
  total_points: number,
  grades: string,
}

export type SimpleStat = {
  term: string,
  count: number,
  average_points: number,
  average_exams: number,
  total_exams: number,
}



export function useData<TQuery extends DataQuery>(county: TQuery['county'], school: TQuery['school'], term: TQuery['term'], background: TQuery['background']) {
  return useQuery<DataResult<TQuery>>(['data', { county, school, term, background }], fetchData, REACT_QUERY_OPTIONS)
}

async function fetchData<TQuery extends DataQuery>(
  key: string,
  params: { county: TQuery['county'], school: TQuery['school'], term: TQuery['term'], background: TQuery['background'] }
): Promise<DataResult<TQuery>> {

  // console.log('Distribution', params)
  var path: string | null = null
  if (params.county !== null && params.school !== null) {
    path = `${API_PATH}/term/${params.term}/${params.background}/counties/${params.county}/schools/${params.school}/v1.json`
  } else if (params.county !== null) {
    path = `${API_PATH}/term/${params.term}/${params.background}/counties/${params.county}/v1.json`
  } else {
    path = `${API_PATH}/term/${params.term}/${params.background}/v1.json`
  }

  const result: OriginalDataResult<TQuery> = await ky.get(path).json()

  return {
    stats: result.stats,
    distribution: {
      distribution: (result.distribution.distribution === null)
        ? null
        : result.distribution.distribution.map(row => ({ ...row, result: numberGrades[row.result] })),
      students: result.distribution.students,
    },
    schools: result.schools,
    grades: result.grades,
  }
}