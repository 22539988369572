import React from 'react'
import styled from 'styled-components'
import { useTerms } from './api/api'
import { Button } from './components/Button'
import constants from './constants'
import { useGlobalState } from './state/hooks'
import { termToObject } from './utils'


function TermSelection() {
  const terms = useTerms().data || []

  const [{selectedTerm, selectedBackground}, dispatch] = useGlobalState()

  const selectTerm = (term: string | undefined) => {
    if (term === undefined) return
    dispatch({
      name: 'selectedTerm/set',
      value: term,
    })
  }

  const switchBackground = () => {
    dispatch({
      name: 'selectedBackground/set',
      value: selectedBackground === 'actual' ? 'all' : 'actual',
    })
  }

  const { isAutumn, year } = termToObject(selectedTerm)

  const termIndex = terms.indexOf(selectedTerm)
  const previous = terms[termIndex - 1]
  const next = terms[termIndex + 1]
  
  return (
    <Bar>
      <Section className="term">
        <Button onClick={() => selectTerm(previous)} disabled={previous === undefined}>Edellinen</Button>
        <div>
        	Valmistumisvuosi<br />
          <span>{isAutumn ? 'Syksy' : 'Kevät'} {year}</span>
        </div>
        <Button onClick={() => selectTerm(next)} disabled={next === undefined}>Seuraava</Button>
      </Section>
      <Section className="background">
        <div>
					Tilastoihin sisällytetään<br />
          <span>
            {selectedBackground === 'actual'
              ? 'vain lukion varsinaiset opiskelijat'
              : 'kaikki lukiossa kokeisiin osallistuneet'}
          </span>
        </div>
        <Button onClick={() => switchBackground()}>
          {/*selectedBackground === 'actual'
            ? 'Näytä kaikki'
						: 'Näytä vain lukion opiskelijat'*/}
					Vaihda
        </Button>
      </Section>
    </Bar>
  )
}

const Bar = styled.div`
  margin: 2rem 0 0 0;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  border-top: 0.5rem solid black;
  background: white;

  display: flex;
  align-items: center;
  flex-wrap: wrap;

	.term {
		div {
			font-size: 1rem;
			text-align: center;
		}

		span {
			${constants.headingFont}
			font-size: 1.4rem;
		}
	}

	.background {
		border-left: 0.2rem solid black;
		div {
			font-size: 1rem;
		}
		span {
			font-size: 1.25rem;
			font-weight: 700;
		}
	}
	
	@media(max-width: 50rem) {
		flex-direction: column;
		align-items: stretch;

		.background {
			border-top: 2px solid black;
			border-left: 0;
		}
	}
`

const Section = styled.section`
	flex: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
  padding: 1rem 1rem;
`

export default TermSelection