import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTerms } from './api/api'

import CountySelection from './components/CountySelection'
import GradeList from './components/GradeList'
import { Section } from './components/layout'
import OutboundLink from './components/OutboundLink'
import ScheduledNotification from './components/ScheduledNotification'
import SchoolSelection from './components/SchoolSelection'
import SimpleStats from './components/SimpleStats'
import Footer from './Footer'

import Header from './Header'
import { StateContext, useGlobalState, useSelectedCountyName, useSelectedSchoolName, useStateReducer } from './state/hooks'
import TermSelection from './TermSelection'
import { trackFakePage } from './tracking'

function App() {
  const terms = useTerms().data
  if (!terms || terms.length === 0) return null
  return (
    <AppMiddle initialTerm={terms[terms.length - 1]} />
  )
}

function AppMiddle(props: { initialTerm: string }) {
  const stateReducer = useStateReducer(props.initialTerm)

  useEffect(() => {
    trackFakePage([props.initialTerm])
  }, [props.initialTerm])

  return (
    <StateContext.Provider value={stateReducer}>
      <AppContent />
    </StateContext.Provider>
  )
}

function AppContent() {
  const [{ selectedCounty, selectedSchool }] = useGlobalState()

  const selectedCountyName = useSelectedCountyName()
  const selectedSchoolName = useSelectedSchoolName()

  return (
    <AppContainerDiv>
      <Header />
      <WelcomeSection>
        <p><strong>Tervetuloa tutustumaan ylioppilastutkinnon tilastoihin!</strong></p>
        <p>
          Tästä sovelluksesta löydät erilaisia tilastoja <strong>valmistuneista ylioppilaista</strong> vuoden 2014 syksystä lähtien.
          Ensin esitellään yleisempiä tilastoja, mutta vähitellen syvennytään yksityiskohtaisempiinkin tietoihin eri oppiaineista ja arvosanoista.
        </p>
        <p>
          Kaikki tässä palvelussa näytettävät tiedot ovat <OutboundLink eventLabel="ytl" href="https://www.ylioppilastutkinto.fi/fi/tietopalvelut/tilastot/oppilaitoskohtaisia-tunnuslukuja" target="_blank" rel="noreferrer">Ylioppilastutkintolautakunnan julkaisemia</OutboundLink>,
          eikä niistä käy ilmi kenenkään henkilöllisyys.
        </p>
        <p>
          Voit valita sivun alareunasta, lasketaanko tilastoihin mukaan vain lukion varsinaiset opiskelijat, vai myös kaikki muut, jotka kirjoittivat ylioppilaskokeensa lukion tiloissa. Heihin kuuluu esimerkiksi kaksoistutkintoa suorittavia ja ammatillisten opintojen pohjalta tutkintoon osallistuvia opiskelijoita. Heidät lasketaan tilastoissa siihen lukioon, jonka tiloissa he kirjoittavat YO-kokeensa, vaikka he eivät olisi osallistuneet lukion omaan opetukseen lainkaan.
        </p>
        <ScheduledNotification />
      </WelcomeSection>

      <TermSelection />
  
      <SimpleStats county={null} school={null} location="Koko Suomi" />
        
      <CountySelection />
      {selectedCounty !== null && <SimpleStats county={selectedCounty} school={null} location={selectedCountyName || '...'} />}
        
      <SchoolSelection />
      {selectedSchool !== null && <SimpleStats county={selectedCounty} school={selectedSchool} location={selectedSchoolName || '...'} />}

      <GradeList />

      <Footer />
    </AppContainerDiv>
  )
}

const AppContainerDiv = styled.div`
  margin: 2rem auto 15rem auto;
  width: 95vw;
  max-width: 90rem;
  overflow: hidden;
  border-left: 0.5rem solid black;
  border-top: 0.5rem solid black;
  font-size: 1.4rem;
`

const WelcomeSection = styled(Section)`
  max-width: 60rem;
  margin: 2rem 0;
`

export default App
