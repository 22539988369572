import React, { useState } from 'react'
import { useData } from '../api/api'
import { useGlobalState } from '../state/hooks'
import { Button } from './Button'
import { Section } from './layout'

const uusimaaMunicipalities: [number | null, string][] = [
  [91, 'Helsinki'],
  [49, 'Espoo'],
  [92, 'Vantaa'],
  [null, 'Muu Uusimaa'],
]

function SchoolSelection() {
  const [{ selectedCounty, selectedSchool, selectedTerm, selectedBackground }, dispatch] = useGlobalState()
  
  const schools = useData(selectedCounty, null, selectedTerm, selectedBackground)

  const [municipality, setMunicipality] = useState<number | null | undefined>(undefined)
  
  const select = (id: number) => {
    dispatch({
      name: 'selectedSchool/toggle',
      value: id,
    })
  }

  return (
    <Section>
      {selectedCounty === 1 && (
        <div>
          <h3><span>1b.</span>Valitse vielä kaupunki</h3>
          {uusimaaMunicipalities.map(([id, name]) => (
            <Button
              key={id}
              onClick={() => setMunicipality(id)}
              selected={municipality === id}
            >
              {name}
            </Button>
          ))}
        </div>
      )}

      <h2><span>2.</span> Valitse lukio</h2>
      <p>Opiskelijan katsotaan kuuluvan siihen lukioon, jossa hän on osallistunut ylioppilastutkinnon kokeisiin.</p>

      {schools.isLoading && (
        <p>Ladataan lukiolistaa...</p>
      )}
      {schools.data && schools.data.schools && (
        schools.data.schools
          .filter(s => {
            if (selectedCounty !== 1) // Not in Uusimaa, show all schools
              return true
            else if (municipality === undefined) // No municipality selected, show nothing
              return false
            else if (municipality !== null) // Real municipality is selected, show matching schools
              return s.municipality_id === municipality
            else // "Other" municipality is selected, show schools not in Helsinki/Espoo/Vantaa
              return uusimaaMunicipalities.findIndex(([id]) => id === s.municipality_id) === -1
          })
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ school_id, name }) => (
            <Button
              key={school_id}
              onClick={() => select(school_id)}
              selected={school_id === selectedSchool}
            >
              {name}
            </Button>
          ))
      )}
    </Section>
  )
}

export default SchoolSelection