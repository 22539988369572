import React, { useState } from 'react'
import styled from 'styled-components'
import { useData } from '../api/api'
import constants from '../constants'
import { useGlobalState, useSelectedSchoolName } from '../state/hooks'
import { trackEvent } from '../tracking'
import { gradeNumbersToLetters, gradeNumberToLetter, termToObject } from '../utils'
import { Button } from './Button'
import { Section } from './layout'


function GradeList() {
  const [{ selectedTerm, selectedCounty, selectedSchool, selectedBackground }] = useGlobalState()

  const [showAll, setShowAll] = useState(false)

  const toggleShowAll = () => {
    if (!showAll) {
      trackEvent('show_grade_list', {
        term: selectedTerm,
        background: selectedBackground,
        county: (selectedCounty !== null) ? selectedCounty.toString() : '-',
        school: (selectedSchool !== null) ? selectedSchool.toString() : '-',
      })
    }
    setShowAll(b => !b)
  }
  
  const selectedSchoolName = useSelectedSchoolName()

  const grades = useData(selectedCounty, selectedSchool, selectedTerm, selectedBackground)
  const term = termToObject(selectedTerm)

  return (
    <Section>
      <h2><span>3.</span> Vertaile lukion tuloksia</h2>

      {selectedSchool !== null && (
        <>

          <h4>{selectedSchoolName}, {term.isAutumn ? 'syksyllä' : 'keväällä'} {term.year} valmistuneiden arvosanat</h4>

          <PointLegendP>
            <strong>Arvosanojen pisteytys</strong>
            <span>L=7</span> <span>E=6</span> <span>M=5</span> <span>C=4</span> <span>B=3</span> <span>A=2</span> <span>I=0</span>
          </PointLegendP>

          {grades.isLoading && (
            <p>Ladataan arvosanoja...</p>
          )}
          {grades.data && grades.data.grades && (
            <TableContainer>
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Keskiarvo</th>
                    <th>Pisteet</th>
                    <th>Arvosanat</th>
                  </tr>
                </thead>
                <tbody>
                  {grades.data.grades.slice(0, showAll ? undefined : 5).map(({ total_points, average_points, grades }, index) => (
                    <tr key={index}>
                      <td className="number">{(index + 1)}.</td>
                      <td><strong>{gradeNumberToLetter(average_points)}</strong> ({average_points.toFixed(2)})</td>
                      <td>{total_points}p</td>
                      <td className="grades">{gradeNumbersToLetters(grades)}</td>
                    </tr>
                  ))}
                </tbody>
                {grades.data.grades.length > 5 &&
                <tfoot>
                  <tr>
                    <td colSpan={4}>
                      <Button onClick={toggleShowAll}>
                        {showAll ? 'Näytä vähemmän' : 'Näytä kaikki...'}
                      </Button>
                    </td>
                  </tr>
                </tfoot>
                }
              </table>
            </TableContainer>
          )}
          {grades.data && grades.data.grades === null && (
            <p>Valmistuneita oli alle 5, joten yksityisyyden vuoksi arvosanoja ei näytetä.</p>
          )}
        </>
      )}
    </Section>
  )
}

const PointLegendP = styled.p`
  /*display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;*/
  display: block;
  margin: 1rem 0;
  text-align: right;

  strong {
    display: block;
  }

  span {
    margin-left: 2vw;
  }
`


const TableContainer = styled.div`
  overflow-x: auto;
  margin: 0 -${constants.pagePadding};
  padding-left: ${constants.pagePadding};

  table {
    border: 0;
    border-spacing: 0;
    margin: 1rem 0 1rem 2rem;

    border-left: 0.3rem solid black;

    @media (max-width: 40rem) {
      margin-left: 0;
    }

    td, th {
      border: 0;
      padding: 0.5rem 1rem;
      font-variant-numeric: tabular-nums;
    }

    thead th {
      border-bottom: 0.3rem solid black;
      text-align: left;
      vertical-align: bottom;
    }

    td.number {
      font-weight: bold;
    }

    td.grades {
      letter-spacing: 2vw;
      font-family: monospace;
      font-size: 1.6rem;
    }

    tfoot td {
      margin-top: 1rem;
      border-top: 0.3rem dotted black;
      text-align: center;
    }
  }
`

export default GradeList