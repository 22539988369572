import React from 'react'
import styled from 'styled-components'
import constants from './constants'


function Header() {
  return (
    <Sheader>
      <h1>yotilastot.fi</h1>
      {/*<nav>
        <a href="&">Tietoja</a>
      </nav>*/}
    </Sheader>
  )
}

const Sheader = styled.header`
  margin: 2rem 0 0 0;
  padding: 0.5rem ${constants.pagePadding};

  color: white;

  background: black;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  h1 {
    ${constants.headingFont}
    font-size: 3rem;
    padding: 0;
    margin: 0.5rem 0;
  }

  nav {
    margin: 1rem 0;

    a {
      /*${constants.headingFont}*/
      font-size: 2rem;
      font-weight: 900;
      color: white;
      text-decoration: none;

      padding: 0.5rem 1rem;
      
      &:hover, &:active {
        background-color: white;
        color: black;
        text-decoration: underline;
      }
    }
  }
`

export default Header