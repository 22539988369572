import React from 'react'
import { trackEvent } from '../tracking'

export default function OutboundLink(props: { eventLabel: string } & React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) {
  const { eventLabel, children, ...restProps } = props
  
  const click = () => {
    trackEvent('click_link', {
      label: eventLabel,
    })
  }

  return <a {...restProps} onClick={click}>{children}</a>
}