import { Background } from '../api/api'
import { trackFakePage } from '../tracking'

export type State = {
  selectedTerm: string,
  selectedCounty: number | null,
  selectedSchool: number | null,
	selectedBackground: Background,
}

export type Action =
  {
    name: 'selectedCounty/toggle',
    value: number | null,
  } | {
    name: 'selectedSchool/toggle',
    value: number | null,
  } | {
    name: 'selectedTerm/set',
    value: string,
  } | {
		name: 'selectedBackground/set',
		value: Background,
	}

export const getDefaultState = (term: string): State => ({
  selectedTerm: term,
  selectedCounty: null,
  selectedSchool: null,
  selectedBackground: 'actual',
})

function innerReducer(state: State, action: Action): State {
  switch (action.name) {
    case 'selectedCounty/toggle':
      return {
        ...state,
        selectedCounty: (state.selectedCounty === action.value)
          ? null
          : action.value,
        selectedSchool: null,
      }
    case 'selectedSchool/toggle':
      return {
        ...state,
        selectedSchool: (state.selectedSchool === action.value)
          ? null
          : action.value,
      }
    case 'selectedTerm/set':
      return {
        ...state,
        selectedTerm: action.value,
      }
    case 'selectedBackground/set':
      return {
        ...state,
        selectedBackground: action.value,
      }
  }
}

export function reducer(state: State, action: Action): State {
  const newState = innerReducer(state, action)
  const segments: string[] = []
  segments.push(newState.selectedTerm)
  segments.push(newState.selectedBackground)
  if (newState.selectedCounty !== null) {
    segments.push(newState.selectedCounty.toString())
    if (newState.selectedSchool !== null) {
      segments.push(newState.selectedSchool.toString())
    }
  }
  trackFakePage(segments)
  return newState
}