import styled from 'styled-components'
import constants from '../constants'

export const Button = styled.button<{ selected?: boolean, disabled?: boolean }>`
  padding: 0.3rem 1rem;
  margin: 0.3rem 0.3rem;
  border: 0.2rem solid black;

  background-color: white;
  font-family: ${constants.textFont};
  font-size: 1.2rem;
  font-weight: 700;
  color: black;

  &:hover {
    background-color: ${constants.color.blue};
    color: white;
  }

  ${p => p.selected === true && `
    background-color: black;
    color: white;
  `}

  ${p => p.disabled === true && `
    opacity: 0.2;
  `}

  cursor: pointer;
`

export default Button
