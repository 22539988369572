import styled from 'styled-components'
import constants from '../constants'

export const Section = styled.section`
    padding: 1rem ${constants.pagePadding};

    h2 {
        ${constants.headingFont}
        font-size: 3rem;
        margin: 2rem 0;
        padding: 0;

        span {
            font-size: 1.5rem;
            margin-right: 0rem;
            color: ${constants.color.blue};
        }
    }

    h3 {
        ${constants.headingFont}
        font-size: 2.3rem;
        margin: 2rem 0;
        padding: 0;

        span {
            font-size: 1.25rem;
            margin-right: 0.5rem;
            color: ${constants.color.blue};
        }
    }

    h4 {
        /*background: black;
        color: white;*/
        padding: 1rem 2rem;
        border-right: 0.5rem solid black;
        border-bottom: 0.5rem solid black;

        font-size: 1.8rem;
    }
`
