

export function termToObject(term: String): { isAutumn: boolean, year: number } {
  return {
    isAutumn: term.charAt(term.length - 1) === 'S',
    year: parseInt(term.slice(0, term.length - 1)),
  }
}



export function gradeNumbersToLetters(numbers: String): String {
  return numbers
    .replaceAll('0', 'I')
    .replaceAll('2', 'A')
    .replaceAll('3', 'B')
    .replaceAll('4', 'C')
    .replaceAll('5', 'M')
    .replaceAll('6', 'E')
    .replaceAll('7', 'L')
}

export const numberGrades = [
  'I',
  'I',
  'A',
  'B',
  'C',
  'M',
  'E',
  'L',
]

export function gradeNumberToLetter(n: number): string {
  const s = n * 8
  if (s < 13) return 'I' // I does not have modifiers

  const base = Math.floor((s + 3) / 8)
  const diff = s % 8

  const modifiers = ['', '+', '½', '-', '']
  const modifier = modifiers[Math.floor((diff + 1) / 2)]

  return numberGrades[base] + modifier
}



export function spacifyNumber(n: string): String {
  if (n.length > 3)
    return spacifyNumber(n.slice(0, n.length - 3)) + ' ' + n.slice(n.length - 3)
  else
    return n
}