import React, { Dispatch, useContext, useReducer } from 'react'
import { useCounties, useData } from '../api/api'
import { Action, getDefaultState, reducer, State } from './state'


type ContextType = [State, Dispatch<Action>]

export const StateContext = React.createContext<ContextType | null>(null)

export function useStateReducer(initialTerm: string) {
  return useReducer(reducer, getDefaultState(initialTerm))
}

export function useGlobalState(): ContextType {
  const c = useContext(StateContext)
  if (c === null) {
    throw new Error('Using StateContext without provider!')
  }
  return c
}


export function useSelectedCountyName(): string | null {
  const [{ selectedCounty }] = useGlobalState()
  const counties = useCounties()
  return counties.find(c => c.id === selectedCounty)?.name || null
}

export function useSelectedSchoolName(): string | null {
  const [{ selectedCounty, selectedSchool, selectedTerm }] = useGlobalState()
  const schools = useData(selectedCounty, null, selectedTerm, 'all')
  return schools.data?.schools?.find(s => s.school_id === selectedSchool)?.name || null
}