import React from 'react'
import { useCounties } from '../api/api'
import { useGlobalState } from '../state/hooks'
import { Button } from './Button'
import { Section } from './layout'


function CountySelection() {
  const counties = useCounties()

  const [{selectedCounty}, dispatch] = useGlobalState()
  
  const select = (id: number) => {
    dispatch({
      name: 'selectedCounty/toggle',
      value: id,
    })
  }

  return (
    <Section>
      <h2><span>1.</span> Valitse maakunta</h2>
      {counties.sort((a,b) => a.name.localeCompare(b.name)).map(({ id, name }) => (
        <Button
          key={id}
          onClick={() => select(id)}
          selected={id === selectedCounty}
        >
          {name}
        </Button>
      ))}
    </Section>
  )
}

export default CountySelection