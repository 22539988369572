import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import { BarChart, CartesianGrid, XAxis, YAxis, Bar, ResponsiveContainer, Tooltip, LabelList, TooltipProps } from 'recharts'

import { useData } from '../api/api'
import constants from '../constants'
import { useGlobalState } from '../state/hooks'
import {  numberGrades } from '../utils'
import { Button } from './Button'
import { Section } from './layout'
import { trackEvent } from '../tracking'

const gradeColors: { [grade: string]: string } = {
  'I': '#F0006D',
  'A': '#DB04F0',
  'B': '#5600FA',
  'C': '#0093E0',
  'M': '#57EDFA',
  'E': '#19F094',
  'L': '#0BF011',
}

const toPercent = (decimal: number, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`

type GradeDistributionProps = {
  county: number | null,
  school: number | null,
  location: string,
}

function GradeDistribution(props: GradeDistributionProps) {
  const [{ selectedTerm, selectedBackground }] = useGlobalState()

  const [showAll, setShowAll] = useState(false)
  const [showPercents/*, setShowPercents*/] = useState(true)

  const toggleShowAll = () => {
    if (!showAll) {
      trackEvent('show_grade_distribution', {
        term: selectedTerm,
        background: selectedBackground,
        county: (props.county !== null) ? props.county.toString() : '-',
        school: (props.school !== null) ? props.school.toString() : '-',
      })
    }
    setShowAll(b => !b)
  }

  const grades = useData(props.county, props.school, selectedTerm, selectedBackground)
  // console.log('grades', props, grades.data)

  // sum of grades over all exams
  const combinedInArr = useMemo(() => {
    if (grades.data === undefined) return undefined
    if (grades.data.distribution.distribution === null) return null

    const combinedInObject = grades.data.distribution.distribution.reduce<{ [result: string]: number }>((sums, curr) => (
      {
        ...sums,
        [curr.result]: (sums[curr.result] || 0) + curr.count
      }
    ), {})
  
    return numberGrades.slice(1).map(result => ({ result, count: combinedInObject[result] || 0 }))
  }, [grades.data])

  // individual exams
  const examsInArr = useMemo(() => {
    if (grades.data === undefined) return undefined
    if (grades.data.distribution.distribution === null || grades.data.distribution.students < 5) return null

    const inObject = grades.data.distribution.distribution.reduce<{ [exam_code: string]: { total: number, [result: string]: number } }>((acc, curr) => (
      {
        ...acc,
        [curr.exam_code]: {
          ...(acc[curr.exam_code] || {}),
          [curr.result]: curr.count,
          total: (acc[curr.exam_code]?.total || 0) + curr.count,
        },
      }
    ), {})
    
    return Object.entries(inObject)
      .map(([exam_code, results]) => ({ exam_code, ...results }))
      .map(results => {
        if (showPercents) {
          return numberGrades.slice(1).reduce<{ total: number, exam_code: string, [result: string]: unknown }>((acc, result) => (
            {
              ...acc,
              [result]: (typeof acc[result] === 'number')
                ? (acc[result] as number) / results.total
                : 0,
            }
          ), results)
        } else {
          return results
        }
      })
      .sort((a, b) => b.total - a.total)
  }, [grades.data, showPercents])

  
  const examsInArrWithSum = useMemo(() => {
    if (combinedInArr === undefined || examsInArr === undefined) return undefined
    if (combinedInArr === null || examsInArr === null) return null
    const totalSum = combinedInArr.reduce((sum, curr) => sum + curr.count, 0)  

    return [
      combinedInArr.reduce((acc, data) => ({ ...acc, [data.result]: data.count / totalSum }), { exam_code: 'YHT', total: totalSum }),
      ...examsInArr,
    ]
  }, [combinedInArr, examsInArr])

  const ticks = useMemo(() => {
    if (combinedInArr === undefined) return undefined
    if (combinedInArr === null) return null
    const totalSum = combinedInArr.reduce((sum, curr) => sum + curr.count, 0)
    return combinedInArr
      .map(d => d.count)
      .reduce<number[]>((arr, curr) => {
        const previous = arr[arr.length - 1] || 0
        return [...arr, previous + curr]
      }, [])
      .map(tick => tick / totalSum)
  }, [combinedInArr])

  return (
    <SSection>
      <h4>Arvosanojen jakautuminen</h4>

      {grades.isLoading && (
        <p>Ladataan arvosanoja...</p>
      )}
      {combinedInArr === null && (
        <p>Valmistuneita oli alle 3, joten yksityisyyden vuoksi mitään arvosanatietoja ei näytetä.</p>
      )}
      {grades.data && combinedInArr !== null && (
        <ChartContainer>
          <MainChartResponsiveContainer>
            <BarChart
              width={1000}
              height={500}
              layout="horizontal"
              data={combinedInArr}
              margin={{ top: 30, right: 0, bottom: 10, left: 0 }}
            >
              <CartesianGrid
                stroke="#DDD"
                strokeWidth="2"
                strokeDasharray="10 10"
                vertical={false}
              />
              <Bar
                dataKey="count"
                fill={constants.color.blue}
                label={{ fill: 'black', fontSize: '1.2rem', fontWeight: 600, position: 'top' }} 
              />
              <XAxis dataKey="result" tickLine={false} />
            </BarChart>
          </MainChartResponsiveContainer>

          <Button onClick={toggleShowAll}>
            {showAll ? 'Piilota koekohtaiset jakaumat' : 'Näytä koekohtaiset jakaumat'}
          </Button>

          {(examsInArrWithSum === null || ticks === null) && (
            <p>Valmistuneita oli alle 5, joten yksityisyyden vuoksi koekohtaisia tietoja ei näytetä.</p>
          )}

          {showAll && examsInArrWithSum !== null && ticks !== null && (
            <ExamChartResponsiveContainer>
              <BarChart
                width={1000}
                height={1000}
                layout="vertical"
                data={examsInArrWithSum}
                margin={{ top: 20, right: 20, bottom: 20, left: 0 }}
              >
                <Tooltip content={CustomTooltip} />
                {numberGrades.slice(1).map(result => (
                  <Bar
                    key={result}
                    dataKey={result}
                    stackId="exam_code"
                    fill={gradeColors[result]}
                  >
                    <LabelList valueAccessor={(data) => data.width > 0 ? result : undefined} position="center" />
                  </Bar>
                ))}
                
                <CartesianGrid
                  stroke="#DDD"
                  strokeWidth="2"
                  horizontal={false}
                />
                <XAxis
                  type="number"
                  tickLine={false} orientation="top"
                  tickFormatter={showPercents ? () => '' : undefined}
                  domain={showPercents ? [0, 1] : undefined}
                  ticks={showPercents ? ticks : undefined}
                />
                <YAxis dataKey="exam_code" tickLine={false} type="category" />
              </BarChart>
            </ExamChartResponsiveContainer>
          )}

        </ChartContainer>
      )}
    </SSection>
  )
}

const CustomTooltip = ({ active, payload, label }: TooltipProps) => {
  if (!active || !payload) return null
  return (
    <SCustomTooltip>
      <p className="label">{label ? (constants.examNames.fi as any)[label] || '-' : '-'}</p>
      
      <table>
        <thead>
          <tr>
            {numberGrades.slice(1).map(grade => (
              <th key={grade}>{grade}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {numberGrades.slice(1).map(grade => (
              <td key={grade}>
                {toPercent(payload[0].payload[grade])}
                <small>{Math.round(payload[0].payload[grade] * payload[0].payload.total)}</small>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </SCustomTooltip>
  )
}

const SCustomTooltip = styled.div`
  background: white;
  border: 0.3rem solid black;
  text-align: center;

  table {
    border-collapse: collapse;

    th {
      border-bottom: 0.2rem solid black;
      text-align: center;
      padding: 0.2rem 0.5rem;
    }
    td {
      font-size: 1.2rem;
      text-align: center;
      padding: 0.2rem 0.5rem;
    }
    small {
      display: block;
      font-size: 1rem;
    }
  }
`

const SSection = styled(Section)`
  h4 {
    border: none;
    text-align: center;
    margin-top: 0;
  }
`

const ChartContainer = styled.div`

  button {
    margin: 2rem auto;
    display: block;
  }

  @media (max-width: 50rem) {
    margin: 0 -${constants.pagePadding};
    /*padding-left: ${constants.pagePadding};*/
  }
`

const MainChartResponsiveContainer = styled(ResponsiveContainer)`
  height: 20rem !important;
  max-width: 50rem;
  margin: 0 auto;
`

const ExamChartResponsiveContainer = styled(ResponsiveContainer)`
  height: 100rem !important;
  max-width: 70rem;
  margin: 0 auto;
`

export default GradeDistribution