import React from 'react'
import styled from 'styled-components'
import constants from './constants'
import OutboundLink from './components/OutboundLink'

function Footer() {
  return (
    <Sfooter>
      <h4>YO-tilastot.fi</h4>
      <hr />
      <small>MUKANA TOTEUTTAMASSA</small>
      <div>
        <span><OutboundLink eventLabel="footerSauceSoft" href="https://saucesoft.io" target="_blank" rel="noreferrer">SauceSoft&nbsp;Oy</OutboundLink></span>
        <span><OutboundLink eventLabel="footerLinkedIn" href="https://www.linkedin.com/in/tuomas-karjalainen/" target="_blank" rel="noreferrer">Tuomas&nbsp;Karjalainen</OutboundLink></span>
      </div>
    </Sfooter>
  )
}

const Sfooter = styled.footer`
  margin: 5rem 0 0 0;
  padding: 0.5rem ${constants.pagePadding};

  color: white;

  background: black;
  text-align: center;

  h4 {
    ${constants.headingFont}
    font-size: 1.5rem;
    padding: 0;
    margin: 2rem 0;
  }

  hr {
    width: 5vw;
    border: 0;
    border-top: 0.2rem solid white;
    margin: 4rem auto 4rem auto;
  }

  small {
    font-size: 0.8rem;
    font-weight: 800;
    letter-spacing: 0.5em;
  }

  div {
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap;

    span {
      flex: 1;
      text-align: center;
    }

    a {
      font-size: 1rem;
      font-weight: 600;
      color: white;
      text-decoration: none;

      display: block;
      padding: 0.5rem 1rem;
      margin: 0.5rem 0.5rem;
      
      &:hover, &:active {
        background-color: white;
        color: black;
        text-decoration: underline;
      }
    }
  }
`

export default Footer